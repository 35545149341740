import {
  Button,
  Paper,
  Divider,
  Grid,
  Icon,
  ListItemButton,
  ListItemText,
  List,
  Typography,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Language } from "../interface";
import { PageTitle } from "./typography/PageTitle";
import { contentText } from "./translate/text";

//英語対応？
export const labelTexts = {
  Japanese: [
    "1. 黒マスと緑マス",
    "2. 線と辺",
    "3. 数字の入力",
    "4. 様々な色のマス",
    "5. 対角線と中線",
    "6. 任意の角度の線",
    "7. 円と直角三角形の配置",
    "8. デジタル数字と矢印",
    "9. 辺や頂点の選択方法(8方向矢印)",
    "10. 特殊な記号と円弧",
    "11. 任意テキストの入力",
    "12. 1〜11のおさらい",
    "ex1. 補助記号",
    "ex2. 特殊な形の盤面",
    "ex3. 便利な入力方法①",
    "ex4. 便利な入力方法②",
  ],
  English: [
    "1. Black/Green cell",
    "2. Line and Edge",
    "3. Number",
    "4. Various color",
    "5. Diagonal/Middle line",
    "6. Optional angle line",
    "7. Circle and Right triangle",
    "8. Digital number and Arrow ",
    "9. 8-way arrow and Boundary imput",
    "10. Battleship and Arc",
    "11. Free text imput",
    "12. Review",
    "13. Auxiliary function",
    "14. Special shape board",
    "15. Convenient input method①",
    "16. Convenient input method②",
  ],
};

const TutorialFloorSelect = ({
  isTutorial,
  isFloorsSolved,
  isFloorsSolvedByGuild,
  isFloorsRevealed,
  language,
}: {
  isTutorial: boolean;
  isFloorsSolved: boolean[];
  isFloorsSolvedByGuild: boolean[];
  isFloorsRevealed: boolean[];
  language: Language;
}) => {
  const floorsCount = 16;
  let history = useHistory();
  const range = (stop: number) =>
    new Array<string>(stop).fill("").map((_, i) => labelTexts[language][i]);

  const [floorButtonText, setFloorButtonText] = useState(range(floorsCount));

  const handleDisplayFloor = (floorIndex: number) => {
    history.push("/tutorial/floor/" + (floorIndex + 1).toString());
  };

  const tableContentMaker = (floorIndex: number) => {
    const isOneFloorSolved = isFloorsSolved[floorIndex];
    const isOneFloorSolvedByGuild = isFloorsSolvedByGuild[floorIndex];
    const isOneFloorRevealed = isFloorsRevealed[floorIndex];
    const oneFloorButtonText = floorButtonText[floorIndex];
    return (
      <ListItemButton
        key={floorIndex}
        className="Solved"
        divider={true}
        onClick={() => handleDisplayFloor(floorIndex)}
      >
        <ListItemText
          sx={{
            fontSize: "medium",
            fontWeight: "medium",
          }}
        >
          {oneFloorButtonText}
        </ListItemText>
      </ListItemButton>
    );
  };

  useEffect(() => {
    setFloorButtonText(range(floorsCount));
  }, [isTutorial, floorsCount]);

  return (
    <div>
      <PageTitle>{contentText.penpaEditTutorial[language]}</PageTitle>
      <Alert severity="info">{contentText.tutorialInfo[language]}</Alert>
      <List>{[...new Array(16)].map((_, i) => tableContentMaker(i))}</List>
      <Grid component="p">
        <Button
          variant="contained"
          startIcon={<Icon>apps</Icon>}
          onClick={() => {
            history.push("/");
          }}
        >
          {contentText.backToMainPage[language]}
        </Button>
      </Grid>
    </div>
  );
};

export default TutorialFloorSelect;
