import {
  Grid,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { saveUserLanguage, saveUserName } from "../api/loadAndSaveDataToDb";
import { Guild, Language, User } from "../interface";
import { ChangeLanguage } from "./ConfigContents/ChangeLanguage";
import { ChangeUserName } from "./ConfigContents/ChangeUserName";
import { Rule } from "./Rule";
import { contentText } from "./translate/text";

export type NewUserProps = {
  guildData: Guild;
  userData: User;
  uid: string;
  isButtonDisabled: boolean;
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NewUser = (props: NewUserProps) => {
  const { isButtonDisabled, setIsButtonDisabled, guildData, userData, uid } =
    props;

  const [newUserState, setNewUserState] = useState("language");
  const [open, setOpen] = useState(false);
  const handleButton = async (language: Language) => {
    // setIsButtonDisabled(true);
    setOpen(true);
    await saveUserLanguage(uid, props.userData, language);
    // setIsButtonDisabled(false);
    setOpen(false);
    setNewUserState("name");
  };

  const [userNameInputValue, setUserNameInputValue] = useState("");
  const [message, setMessage] = useState("");
  const [showBackButton, setShowBackButton] = useState(false);

  const clearMessage = () => {
    setMessage("");
  };
  const handleUserNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const newName = e.target.value;
    setUserNameInputValue(newName);
  };
  const handleUserNameSaveButton = async () => {
    // setIsButtonDisabled(true);
    setOpen(true);
    await saveUserName(
      uid,
      userNameInputValue,
      true,
      setMessage,
      setShowBackButton
    );
    // setIsButtonDisabled(false);
    setOpen(false);
    setNewUserState("rule");
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {newUserState == "language" && (
        <>
          <Grid item xs={12}>
            <h1> Welcome To Instructionless Grid!</h1>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="warning">
              <AlertTitle>Caution</AlertTitle>
              (For smartphone users) Do not proceed using the Twitter built-in
              browser as your data will not be saved. Please use browser
              applications like Chrome, Safari, Edge or Firefox.
            </Alert>

            <Alert severity="warning" sx={{ my: 1 }}>
              <AlertTitle>注意</AlertTitle>
              スマートフォンで解く場合、twitter公式のブラウザで開くとアカウント情報が保存されない恐れがあります。Chromeなどのブラウザアプリで開いてください。
            </Alert>
            <p>
              Choose your language.
              <br />
              使用する言語を選択してください
            </p>

            <p>
              You can change this at any time.
              <br />
              後からでも変更できます。
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <Button
                variant="contained"
                disabled={isButtonDisabled}
                onClick={() => handleButton("English")}
              >
                English
              </Button>{" "}
              <Button
                variant="contained"
                disabled={isButtonDisabled}
                onClick={() => handleButton("Japanese")}
              >
                日本語
              </Button>
            </p>
          </Grid>
        </>
      )}
      {newUserState == "name" && (
        <>
          <Grid item xs={12}>
            <h1>{contentText.welcome[userData.language]}</h1>
          </Grid>
          <Grid item xs={12}>
            <p>{contentText.newUserName1[userData.language]}</p>
            <p>{contentText.newUserName2[userData.language]}</p>
          </Grid>
          <Grid>
            <p>
              <TextField
                variant="outlined"
                label={contentText.userName[userData.language]}
                value={userNameInputValue}
                onChange={handleUserNameInputValue}
              ></TextField>
            </p>
            <Button
              variant="contained"
              disabled={isButtonDisabled}
              onClick={handleUserNameSaveButton}
            >
              {contentText.register[userData.language]}
            </Button>
          </Grid>
        </>
      )}
      {newUserState == "rule" && (
        <>
          {" "}
          <Grid item xs={12}>
            <h1>
              {contentText.hello[userData.language]}
              {userData.userName}
              {contentText.san[userData.language]}
            </h1>
          </Grid>
          <Rule {...props} />
        </>
      )}
    </>
  );
};
