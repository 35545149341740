import { Grid, Button, Icon } from "@mui/material";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Guild, User } from "../interface";
import { NewUserProps } from "./NewUser";
import { contentText } from "./translate/text";
import { PageTitle } from "./typography/PageTitle";

type RuleProps = {
  guildData: Guild;
  userData: User;
  uid: string;
};

export const Rule = (props: RuleProps) => {
  const { guildData, userData, uid } = props;
  const history = useHistory();
  const handleStartButton = async () => {
    history.push("/");
  };
  const handleTutorialButton = async () => {
    history.push("/tutorial");
  };

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const onResize = useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, [setSize]);

  window.addEventListener("resize", onResize, true);

  return (
    <>
      <Grid item xs={12}>
        <PageTitle>{contentText.rule[userData.language]}</PageTitle>
      </Grid>
      <Grid item xs={12}>
        {userData.language == "Japanese" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe
              src="https://docs.google.com/presentation/d/e/2PACX-1vTPbZQJMFYMklI7dm06g4DcfKbVOX4QHXtL9-MqKDZmh5lIBtHr-O3eYpvA9-Z61nlgzQet-Pw-pIpV/embed?start=false&loop=false&delayms=10000"
              frameborder="0"
              width=${Math.min(size.width * 0.9, 600)}
              height=${(Math.min(size.width * 0.9, 600) * 3) / 4}
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
            ></iframe>`,
            }}
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://docs.google.com/presentation/d/e/2PACX-1vT_xg__TNqGHoQaDs7dJhAyPbViNKOZ4W_gqdRDdbSqjGTtXfvSV6QOa9KtX_epHBNBKq38ULoRrFho/embed?start=false&loop=false&delayms=10000"
                   frameborder="0"
                    width=${Math.min(size.width * 0.9, 600)}
                    height=${(Math.min(size.width * 0.9, 600) * 3) / 4}
                   allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>`,
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Button
          variant="contained"
          onClick={handleStartButton}
          startIcon={<Icon>apps</Icon>}
        >
          {contentText.startGame[userData.language]}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Button
          variant="contained"
          onClick={handleTutorialButton}
          startIcon={<Icon>menu_book</Icon>}
        >
          {contentText.startTutorial[userData.language]}
        </Button>
      </Grid>
    </>
  );
};
