import {
  Typography,
  Button,
  Divider,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Paper,
  CardContent,
  Icon,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { loadFloorData } from "../api/loadAndSaveDataToDb";
import { initialFloor } from "../data/initialData";
import { User } from "../interface";
import AnswerKeyInput from "./AnswerKeyInput";
import { contentText } from "./translate/text";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PageTitle } from "./typography/PageTitle";

const Endroll = ({ userData }: { userData: User }) => {
  const floorName = "endroll";
  const floorIndex = "endroll";
  let history = useHistory();

  const [isLastFloorSolved, setIsLastFloorSolved] = useState(false);

  //floorDataをサーバーから持ってくる
  const [floorData, setFloorData] = useState(initialFloor);

  useEffect(() => {
    const newIsLastFloorSolved =
      userData?.floors["lastFloor"]?.clearTime !== undefined;
    setIsLastFloorSolved(newIsLastFloorSolved);
  }, [userData]);

  useEffect(() => {
    if (isLastFloorSolved) {
      loadFloorData(floorName, setFloorData);
      //29a8213f
    }
  }, [isLastFloorSolved]);

  const [size, setSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const onResize = React.useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, [setSize]);

  window.addEventListener("resize", onResize, true);

  return (
    <div>
      {isLastFloorSolved ? (
        <div>
          <PageTitle>{contentText.endrollTitle[userData.language]}</PageTitle>
          <Card sx={{ my: 2 }}>
            <CardContent>
              <Typography variant="h4" component="p">
                {contentText.example[userData.language]}
              </Typography>
              <img src={floorData.exampleImage} width="50%" height="auto"></img>
              <img
                src={floorData.exampleAnswerImage}
                width="50%"
                height="auto"
              ></img>
              <Grid component="p">
                <Button
                  variant="contained"
                  onClick={() => window.open(floorData.exampleLink)}
                >
                  {contentText.openAsPenpaEdit[userData.language]}
                </Button>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ my: 2 }}>
            <CardContent>
              <Typography variant="h4" component="p">
                {contentText.problem[userData.language]}
              </Typography>
              {size.width > 500 ? (
                <div
                  style={{
                    width: "100%",
                    height: "960px",
                  }}
                >
                  <iframe
                    title={"problem"}
                    src={floorData.problemLink}
                    width="100%"
                    height="960px"
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "576px",
                    height: "580px",
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    title={"problem"}
                    src={floorData.problemLink}
                    width="90%"
                    height="960px"
                    style={{
                      transformOrigin: "0 0",
                      transform: "scale(0.59)",
                      overflow: "hidden",
                    }}
                  />
                </div>
              )}

              <Button
                variant="contained"
                onClick={() => window.open(floorData.problemLink)}
                sx={{ my: 2 }}
              >
                {contentText.openAsPenpaEdit[userData.language]}
              </Button>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div>{contentText.invalidProblemAccess[userData.language]} </div>
      )}

      <Grid component="div" sx={{ my: 1 }}>
        <Button
          variant="contained"
          startIcon={<Icon>apps</Icon>}
          onClick={() => {
            history.push("/");
          }}
        >
          {contentText.backToMainPage[userData.language]}
        </Button>
      </Grid>
    </div>
  );
};
export default Endroll;
