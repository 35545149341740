import { Grid, Typography, Button, Card, CardContent } from "@mui/material";
import { saveUserLanguage } from "../../api/loadAndSaveDataToDb";
import { Language } from "../../interface";
import { ConfigProps } from "../Config";
import { contentText } from "../translate/text";

export const ChangeLanguage = ({
  props,
  setIsButtonDisabled,
}: {
  props: ConfigProps;
  setIsButtonDisabled: (arg: boolean) => void;
}) => {
  const handleButton = async (language: Language) => {
    setIsButtonDisabled(true);
    await saveUserLanguage(props.uid, props.userData, language);
    setIsButtonDisabled(false);
  };
  return (
    <Card sx={{ my: 2 }}>
      <CardContent>
        <Grid item component="div">
          <Typography variant="h4" component="h1">
            {contentText.changeLanguage[props.userData.language]}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Button variant="contained" onClick={() => handleButton("Japanese")}>
            日本語
          </Button>{" "}
          <Button variant="contained" onClick={() => handleButton("English")}>
            English
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};
