import { Guild, User } from "../../interface";
import firebase from "firebase";
import { useState, useEffect } from "react";
import {
  changeGuildOwner,
  getUserName,
  kickMember,
} from "../../api/loadAndSaveDataToDb";
import StarIcon from "@mui/icons-material/Star";
import { contentText } from "../translate/text";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useRadioGroup,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ShowGuildMemberList = ({
  guildData,
  userData,
  uid,
  setIsButtonDisabled,
  memberNameList,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
  setIsButtonDisabled: (arg: boolean) => void;
  memberNameList: { memberName: string; memberId: string }[];
}) => {
  const [isKickButtonShown, setIsKickButtonShown] = useState(false);

  useEffect(() => {
    userData.joinedGuildId === userData.guildId &&
    guildData.memberIds.length > 1
      ? setIsKickButtonShown(true)
      : setIsKickButtonShown(false);
  }, [userData.joinedGuildId, userData.guildId, guildData.memberIds]);

  const [kickDialogOpen, setKickDialogOpen] = useState(false);
  const [kickResultDialogOpen, setKickResultDialogOpen] = useState(false);
  const [kickMemberId, setKickMemberId] = useState("");
  const [kickMemberName, setKickMemberName] = useState("");
  const [kickResult, setKickResult] = useState(false);

  const handleKickDialogOpen = (memberName: string, memberId: string) => {
    setKickMemberName(memberName);
    setKickMemberId(memberId);
    setKickDialogOpen(true);
  };
  const handleKickDialogClose = () => {
    setKickDialogOpen(false);
  };

  const handleKickResultDialogOpen = () => {
    setKickResultDialogOpen(true);
  };
  const handleKickResultDialogClose = () => {
    setKickResultDialogOpen(false);
  };

  const handleKickButton = async (memberId: string) => {
    setIsButtonDisabled(true);
    handleKickDialogClose();
    const result = await kickMember(uid, memberId);
    setKickResult(result?.data ?? false);
    setIsButtonDisabled(false);
    handleKickResultDialogOpen();
  };

  return (
    <Grid item xs={12}>
      {guildData.name ? (
        <>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {guildData.name}
          </Typography>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              m: 0,
            }}
            component="ul"
          >
            {memberNameList.map((data) => {
              let icon;

              if (data.memberId === guildData.guildOwner) {
                icon = <StarIcon />;
              }

              return (
                <ListItem key={data.memberId}>
                  <Chip
                    icon={icon}
                    label={data.memberName}
                    onDelete={
                      isKickButtonShown && data.memberId !== uid
                        ? () => {
                            handleKickDialogOpen(
                              data.memberName,
                              data.memberId
                            );
                          }
                        : undefined
                    }
                  />
                </ListItem>
              );
            })}
          </Paper>
          <Dialog
            open={kickDialogOpen}
            onClose={handleKickDialogClose}
            aria-labelledby="alert-kickdialog-title"
            aria-describedby="alert-kickdialog-description"
          >
            <DialogTitle id="alert-kickdialog-title">
              {contentText.banPlayer[userData.language]}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-kickdialog-description">
                {contentText.userName[userData.language]}:{kickMemberName}
                <br />
                ID:{kickMemberId}
                <br />
                {contentText.kickMember[userData.language]}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleKickDialogClose}>
                {contentText.cancel[userData.language]}
              </Button>
              <Button
                onClick={() => {
                  handleKickButton(kickMemberId);
                }}
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={kickResultDialogOpen}
            onClose={handleKickResultDialogClose}
            aria-labelledby="alert-kickresultdialog-title"
            aria-describedby="alert-kickresultdialog-description"
          >
            <DialogTitle id="alert-kickresultdialog-title">
              {contentText.banPlayer[userData.language]}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-kickresultdialog-description">
                {kickResult ? (
                  <>
                    {contentText.userName[userData.language]}:{kickMemberName}
                    <br />
                    ID:{kickMemberId}
                    <br />
                    {contentText.banPlayerSuccess[userData.language]}
                  </>
                ) : (
                  <>{contentText.banPlayerFailed[userData.language]}</>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleKickResultDialogClose}>
                {contentText.close[userData.language]}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <>{contentText.notInAGuild[userData.language]}</>
      )}
    </Grid>
  );
};
