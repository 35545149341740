import {
  Button,
  Paper,
  Divider,
  Grid,
  Icon,
  Pagination,
  PaginationItem,
  Box,
  Badge,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Guild, Language, User } from "../interface";
import { PageTitle } from "./typography/PageTitle";
import { contentText } from "./translate/text";
import TwitterIcon from "@mui/icons-material/Twitter";
import ReceiptIcon from "@mui/icons-material/Receipt";

const FloorSelect = ({
  isTutorial,
  isFloorsSolved,
  isFloorsSolvedByGuild,
  isFloorsRevealed,
  language,
  guildData,
  userData,
}: {
  isTutorial: boolean;
  isFloorsSolved: boolean[];
  isFloorsSolvedByGuild: boolean[];
  isFloorsRevealed: boolean[];
  language: Language;
  guildData: Guild;
  userData: User;
}) => {
  const floorsCount = isTutorial ? 16 : 100;
  let history = useHistory();

  const range = (stop: number) =>
    new Array<string>(stop).fill("").map((_, i) => (i + 1).toString());

  const [floorButtonText, setFloorButtonText] = useState(range(floorsCount));

  const handleDisplayFloor = (floorIndex: number) => {
    isTutorial
      ? history.push("/tutorial/floor/" + (floorIndex + 1).toString())
      : isFloorsRevealed[floorIndex] &&
        history.push("/floor/" + (floorIndex + 1).toString());
  };

  useEffect(() => {
    setFloorButtonText(range(floorsCount));
  }, [isTutorial, floorsCount]);

  const handleTwitterButton = () => {
    if (language === "Japanese") {
      window.open(
        `https://twitter.com/intent/tweet?text=ギルドで合計${guildData.solvedCount}問を解き明かした！%20%23InstructionlessGrid&url=https://ig.logicpuzzle.app/`
      );
    } else {
      window.open(
        `https://twitter.com/intent/tweet?text=Our%20guild%20has%20solved%20${guildData.solvedCount}%20puzzles%20on%20the%20%23InstructionlessGrid&url=https://ig.logicpuzzle.app/`
      );
    }
  };

  const handleEndroll = () => {
    history.push("/endroll");
  };

  return (
    <div>
      <PageTitle>{contentText.mainGrid[language]}</PageTitle>
      <p>
        <Button
          variant="contained"
          startIcon={<Icon>menu_book</Icon>}
          onClick={() => {
            history.push("/tutorial");
          }}
        >
          {contentText.goToTutorial[language]}
        </Button>
      </p>
      <Box
        sx={{
          flexWrap: "wrap",
          display: "grid",
          gridTemplateColumns: "repeat(10, 1fr)",
          width: "100%",
        }}
      >
        {[...new Array(100)].map((e, i) => {
          const isOneFloorSolved = isFloorsSolved[i];
          const isOneFloorSolvedByGuild = isFloorsSolvedByGuild[i];
          const isOneFloorRevealed = isFloorsRevealed[i];
          const oneFloorButtonText = floorButtonText[i];
          return (
            <Button
              key={i}
              variant={
                isOneFloorSolved || isOneFloorSolvedByGuild
                  ? "contained"
                  : "outlined"
              }
              sx={{
                m: 0.1,
                minWidth: 0,
                fontSize: "min(2.5em, 4.5vw)",
                paddingTop: "50%",
                paddingBottom: "50%",
                height: 0,
              }}
              color={
                isOneFloorSolved
                  ? "success"
                  : isOneFloorSolvedByGuild
                  ? "info"
                  : undefined
              }
              disabled={
                !isOneFloorSolved &&
                !isOneFloorSolvedByGuild &&
                !isOneFloorRevealed
              }
              onClick={() => handleDisplayFloor(i)}
            >
              {i + 1}
            </Button>
          );
        })}
      </Box>
      <Grid component="div" sx={{ my: 2 }}>
        {isTutorial ? (
          <Button
            variant="contained"
            startIcon={<Icon>apps</Icon>}
            onClick={() => {
              history.push("/");
            }}
            size="large"
          >
            {contentText.backToMainPage[language]}
          </Button>
        ) : (
          <>
            <Grid component="p" sx={{ my: 1 }}>
              <Button
                variant="contained"
                startIcon={<Icon>grid_view</Icon>}
                onClick={() => {
                  history.push("/last");
                }}
              >
                {contentText.goToLastProblem[language]}
              </Button>
            </Grid>
            {userData.floors["lastFloor"] && (
              <Grid component="p" sx={{ my: 1 }}>
                <Button
                  variant="contained"
                  startIcon={<ReceiptIcon />}
                  sx={{ my: 1 }}
                  onClick={handleEndroll}
                >
                  {contentText.endroll[language]}
                </Button>
              </Grid>
            )}
            <Grid component="p" sx={{ my: 1 }}>
              <Button
                variant="contained"
                startIcon={<TwitterIcon />}
                color="info"
                sx={{ my: 1 }}
                onClick={handleTwitterButton}
              >
                {contentText.tweetProgress[language]}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default FloorSelect;
