import { UserWithId } from "../api/loadAndSaveDataToDb";
import { Guild, User, Floor } from "../interface";

export const initialUser: User = {
  userName: null,
  guildId: "",
  joinedGuildId: "",
  solvedCount: 0,
  floors: {},
  language: "Japanese",
};
export const initialGuild: Guild = {
  name: null,
  memberIds: [""],
  floors: {},
  solvedCount: 0,
  active: false,
  guildOwner: "",
};
export const initialFloor: Floor = {
  exampleImage: "",
  exampleAnswerImage: "",
  exampleLink: "",
  exampleAnswerKey: "",
  problemImage: "",
  problemLink: "",
  hashedAnswer: "",
  additionalExampleCount: 0,
  additionalExampleImage: [],
};

export const initialUserWithId: UserWithId = {
  userName: null,
  guildId: "",
  joinedGuildId: "",
  solvedCount: 0,
  floors: {},
  language: "Japanese",
  uid: "",
};
