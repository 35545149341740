import {
  TableCell,
  TableRow,
  Paper,
  Table,
  Divider,
  Button,
  Icon,
  Box,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getLastFloorData } from "../api/loadAndSaveDataToDb";
import { Guild, User } from "../interface";
import AnswerKeyInput from "./AnswerKeyInput";
import { PageTitle } from "./typography/PageTitle";
import { contentText } from "./translate/text";

export const LastFloor = ({
  guildData,
  userData,
  uid,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
}) => {
  const floorsCount = 100;
  let history = useHistory();

  const magicSquare = [
    26, 76, 43, 81, 33, 35, 40, 47, 79, 45, 65, 22, 56, 21, 61, 14, 78, 77, 100,
    11, 88, 89, 27, 49, 90, 6, 73, 10, 18, 55, 74, 34, 36, 97, 15, 83, 1, 60,
    66, 39, 2, 31, 50, 87, 99, 48, 58, 63, 13, 54, 16, 93, 29, 5, 94, 86, 69,
    24, 82, 7, 92, 28, 91, 20, 9, 38, 4, 98, 30, 95, 3, 53, 70, 85, 17, 44, 59,
    23, 80, 71, 64, 42, 41, 52, 68, 84, 51, 46, 25, 32, 75, 37, 62, 8, 19, 67,
    72, 57, 12, 96,
  ];
  const [hashedLastAnswer, setHashedLastAnswer] = useState("");

  const [lastFloorCellContent, setLastFloorCellContent] = useState(
    magicSquare.map((e) => e.toString())
  );
  useEffect(() => {
    getLastFloorData().then((lastFloorData) => {
      setLastFloorCellContent(
        magicSquare.map((floorIndex) => {
          return guildData.floors[floorIndex.toString()]
            ? lastFloorData?.cellData[floorIndex.toString()] ??
                floorIndex.toString()
            : floorIndex.toString();
        })
      );
      setHashedLastAnswer(lastFloorData?.hashedAnswer);
    });
  }, [guildData.floors, userData.joinedGuildId]);

  return (
    <>
      <PageTitle>{contentText.finalProblem[userData.language]}</PageTitle>
      <Alert severity="warning" sx={{ my: 1 }}>
        <AlertTitle>{contentText.caution[userData.language]}</AlertTitle>
        {contentText.lastProblemCaution[userData.language]}
      </Alert>
      <Box
        sx={{
          flexWrap: "wrap",
          display: "grid",
          gridTemplateColumns: "repeat(10, 1fr)",
          width: "100%",
        }}
      >
        {[...new Array(floorsCount)].map((e, i) =>
          guildData.floors[magicSquare[i]] ? (
            <Button
              key={i}
              disableRipple={true}
              disableFocusRipple={true}
              variant="outlined"
              sx={{
                m: 0.1,
                minWidth: 0,
                fontSize: "min(2.5em, 4.5vw)",
                paddingTop: "50%",
                paddingBottom: "50%",
                height: 0,
              }}
            >
              {lastFloorCellContent[i]}
            </Button>
          ) : (
            <Button
              key={i}
              disabled={true}
              sx={{
                m: 0.1,
                minWidth: 0,
                fontSize: "min(2.5em, 4.5vw)",
                paddingTop: "50%",
                paddingBottom: "50%",
                height: 0,
              }}
            >
              {lastFloorCellContent[i]}
            </Button>
          )
        )}
      </Box>
      <Grid item xs={12} sx={{ my: 2 }}>
        <AnswerKeyInput
          uid={uid}
          hashedAnswer={hashedLastAnswer}
          floorId="lastFloor"
          userData={userData}
          floorName="lastFloor"
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Button
          variant="contained"
          startIcon={<Icon>apps</Icon>}
          onClick={() => {
            history.push("/");
          }}
        >
          {contentText.backToMainPage[userData.language]}
        </Button>
      </Grid>
    </>
  );
};
