import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GroupIcon from "@mui/icons-material/Group";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import StarIcon from "@mui/icons-material/Star";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  CardContent,
  Container,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  ThemeOptions,
  ThemeProvider,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import firebase from "firebase/app";
import "firebase/auth";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Switch } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import {
  getRevealedFloorNumber,
  getUserParentUid,
  loadGuildData,
  loadUserData,
  UserWithId,
} from "./api/loadAndSaveDataToDb";
import { Config } from "./components/Config";
import Floor from "./components/Floor";
import FloorSelect from "./components/FloorSelect";
import { LastFloor } from "./components/LastFloor";
import { Rule } from "./components/Rule";
import { contentText } from "./components/translate/text";
import { TutorialFloor } from "./components/TutorialFloor";
import TutorialFloorSelect from "./components/TutorialFloorSelect";
import "./css/App.css";
import {
  initialGuild,
  initialUser,
  initialUserWithId,
} from "./data/initialData";
import Endroll from "./components/endroll";
import Ranking from "./components/Ranking";
import TestAllRanking from "./components/TestAllRanking";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#3e2723",
    },
    secondary: {
      main: "#f50057",
    },
    success: {
      main: "#00c853",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2196f3",
    },
  },
};

const theme = createTheme(themeOptions);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

let stopUserDataSnapshot = () => {};
let stopGuildDataSnapshot = () => {};

function App() {
  const floorNumber = 100;

  let history = useHistory();
  //login
  const [user, loading, error] = useAuthState(firebase.auth());
  if (error) {
    console.log(error);
  }
  //userData取得
  const [userData, setUserData] = useState(initialUser);
  const [guildData, setGuildData] = useState(initialGuild);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [isFloorsSolved, setIsFloorsSolved] = useState<boolean[]>(
    Array(floorNumber).fill(false)
  );
  const [isFloorsSolvedByGuild, setIsFloorsSolvedByGuild] = useState<boolean[]>(
    Array(floorNumber).fill(false)
  );
  const [isFloorsRevealed, setIsFloorsRevealed] = useState<boolean[]>(
    Array(floorNumber).fill(false)
  );
  const [userDataWithUid, setUserDataWithUid] = useState(initialUserWithId);

  const location = useLocation();

  const [revealedFloorNumber, setRevealedFloorNumber] = useState(2);
  const [uid, setUid] = useState("");

  useEffect(() => {
    if (location.pathname === "/") {
      (async () => {
        let newrevealedFloorNumber = await getRevealedFloorNumber();

        if (newrevealedFloorNumber) {
          if (Object.keys(guildData.floors).length >= 50) {
            newrevealedFloorNumber++;
          }
          setRevealedFloorNumber(newrevealedFloorNumber);
        }
      })();
    }
  }, [location.pathname, guildData.floors]);
  // const classes = useStyles();

  useEffect(() => {
    const f = async () => {
      if (user?.uid) {
        const parentUid = await getUserParentUid(user.uid);
        setUid(parentUid ?? user.uid);
      }
    };
    f();
  }, [loading, user?.uid]);
  //useEffects
  useEffect(() => {
    if (!loading && uid !== "") {
      const f = () => {
        stopUserDataSnapshot();
        stopUserDataSnapshot = loadUserData(
          uid,
          setUserData,
          setUserDataLoaded
        );
      };
      f();
    }
  }, [loading, uid]);

  useEffect(() => {
    if (!loading && !userData.userName && userDataLoaded) {
      if (location.pathname != "/config/new-user") {
        history.push("/config/new-user");
      }
    }
  }, [location, loading, userData.userName, userDataLoaded]);

  useEffect(() => {
    if (userData.joinedGuildId !== "") {
      stopGuildDataSnapshot();
      stopGuildDataSnapshot = loadGuildData(
        userData.joinedGuildId,
        setGuildData
      );
    }
  }, [userData.joinedGuildId]);

  useEffect(() => {
    let newIsFloorsSolved: boolean[] = Array(floorNumber).fill(false);
    newIsFloorsSolved = newIsFloorsSolved.map(
      (_, floorIndex) =>
        !(userData.floors[(floorIndex + 1).toString()] === undefined)
    );
    setIsFloorsSolved(newIsFloorsSolved);
  }, [userData.floors]);

  useEffect(() => {
    let newIsFloorsSolvedByGuild: boolean[] = Array(floorNumber).fill(false);
    newIsFloorsSolvedByGuild = newIsFloorsSolvedByGuild.map(
      (_, floorIndex) =>
        !(guildData.floors[(floorIndex + 1).toString()] === undefined)
    );
    setIsFloorsSolvedByGuild(newIsFloorsSolvedByGuild);

    if (guildData.name === "") {
      setGuildData({
        name: null,
        memberIds: guildData.memberIds,
        floors: guildData.floors,
        solvedCount: guildData.solvedCount,
        active: guildData.active,
        guildOwner: guildData.guildOwner,
      });
      // guildData.name = null;
    }
  }, [guildData.floors]);

  useEffect(() => {
    let revealedFloorNumberCount = revealedFloorNumber;
    let newIsFloorsRevealed: boolean[] = Array(floorNumber).fill(false);
    for (let i = 0; i < floorNumber; i++) {
      if (isFloorsSolvedByGuild[i] || isFloorsSolved[i]) {
        newIsFloorsRevealed[i] = true;
      } else if (revealedFloorNumberCount > 0) {
        newIsFloorsRevealed[i] = true;
        revealedFloorNumberCount--;
      } else {
        newIsFloorsRevealed[i] = false;
      }
    }
    setIsFloorsRevealed(newIsFloorsRevealed);
  }, [isFloorsSolvedByGuild, isFloorsSolved, revealedFloorNumber]);

  useEffect(() => {
    const newUserDataWithUid: UserWithId = {
      uid: uid,
      userName: userData.userName,
      guildId: userData.guildId,
      joinedGuildId: userData.joinedGuildId,
      solvedCount: userData.solvedCount,
      floors: userData.floors,
      language: userData.language,
      isAccountLinkedWithMail: userData.isAccountLinkedWithMail,
      parentUid: userData.parentUid,
    };
    setUserDataWithUid(newUserDataWithUid);
  }, [uid, userData]);

  const showFloorSelect = (isTutorial: boolean) => {
    return isTutorial ? (
      <TutorialFloorSelect
        isTutorial={isTutorial}
        isFloorsSolved={isFloorsSolved}
        isFloorsRevealed={isFloorsRevealed}
        isFloorsSolvedByGuild={isFloorsSolvedByGuild}
        language={userData.language}
      />
    ) : (
      <FloorSelect
        isTutorial={isTutorial}
        isFloorsSolved={isFloorsSolved}
        isFloorsRevealed={isFloorsRevealed}
        isFloorsSolvedByGuild={isFloorsSolvedByGuild}
        language={userData.language}
        guildData={guildData}
        userData={userData}
      />
    );
  };
  return (
    <ThemeProvider theme={theme}>
      {loading || !user ? (
        <>
          <LinearProgress />
          <div>loading...</div>
        </>
      ) : (
        <>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => {
                  history.push("/");
                }}
              >
                <HomeIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Instructionless Grid
              </Typography>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      {...bindTrigger(popupState)}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      {...bindMenu(popupState)}
                    >
                      <CardContent>
                        {userData.userName} ({guildData.name ?? "未所属"})<br />
                        {uid}
                      </CardContent>
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          history.push("/config/user");
                        }}
                        sx={{ width: 320 }}
                      >
                        <ListItemIcon>
                          <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          {contentText.userConfig[userData.language]}
                        </ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          history.push("/config/guild");
                        }}
                        sx={{ width: 320 }}
                      >
                        <ListItemIcon>
                          <GroupIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          {contentText.guildConfig[userData.language]}
                        </ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          history.push("/ranking");
                        }}
                        sx={{ width: 320 }}
                      >
                        <ListItemIcon>
                          <StarIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          {contentText.ranking[userData.language]}
                        </ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close();
                          history.push("/instruction");
                        }}
                        sx={{ width: 320 }}
                      >
                        <ListItemIcon>
                          <InfoIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          {contentText.instruction[userData.language]}
                        </ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </Toolbar>
          </AppBar>
          <Container maxWidth="md">
            <Switch>
              <Route exact path="/" render={() => showFloorSelect(false)} />
              <Route path="/floor/:floorIndex">
                <Floor
                  isFloorsRevealed={isFloorsRevealed}
                  userData={userData}
                  uid={uid}
                />
              </Route>
              <Route
                exact
                path="/tutorial"
                render={() => showFloorSelect(true)}
              />
              <Route path="/tutorial/floor/:floorIndex">
                <TutorialFloor language={userData.language} />
              </Route>
              <Route path="/config/">
                <Config
                  uid={uid}
                  userData={userData}
                  guildData={guildData}
                  setUid={setUid}
                />
              </Route>
              <Route path="/instruction">
                <Rule uid={uid} userData={userData} guildData={guildData} />
              </Route>
              <Route path="/ranking">
                <Ranking guildData={guildData} userData={userData} />
              </Route>
              <Route path="/test-all-ranking">
                <TestAllRanking guildData={guildData} userData={userData} />
              </Route>
              <Route path="/last">
                <LastFloor
                  guildData={guildData}
                  userData={userData}
                  uid={uid}
                />
              </Route>
              <Route path="/endroll">
                <Endroll userData={userData} />
              </Route>
            </Switch>
          </Container>
        </>
      )}
    </ThemeProvider>
  );
}

export default App;
