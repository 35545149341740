import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Icon,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ConfigProps } from "../Config";
import { PageTitle } from "../typography/PageTitle";
import { contentText } from "../translate/text";
import { ChangeGuildName } from "./ChangeGuildName";
import { LeaveGuild } from "./LeaveGuild";
import { MakeGuildInviteCode } from "./MakeGuildInviteCode";
import { ReceiveGuildInviteCode } from "./ReceiveGuildInviteCode";
import { ShowGuildMemberList } from "./ShowGuildMemberList";
import { SectionTitle } from "../typography/SectionTitle";
import { ChangeGuildOwner } from "./ChangeGuildOwner";
import { getUserName } from "../../api/loadAndSaveDataToDb";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

export const GuildConfig = ({
  props,
  isButtonDisabled,
  setIsButtonDisabled,
}: {
  props: ConfigProps;
  isButtonDisabled: boolean;
  setIsButtonDisabled: (arg: boolean) => void;
}) => {
  const { guildData, userData, uid } = props;
  const history = useHistory();
  const classes = useStyles();

  const [memberNameList, setMemberNameList] = useState([
    { memberName: "", memberId: "" },
  ]);
  useEffect(() => {
    const f = async () => {
      let newMemberNameList = await Promise.all(
        guildData.memberIds.map(async (memberId) => {
          if (!memberId) {
            return { memberName: "", memberId: "" };
          }
          const oneMemberName = await getUserName(memberId);
          return oneMemberName;
        })
      );
      newMemberNameList = newMemberNameList.filter(
        (value) => value.memberName !== ""
      );
      setMemberNameList(newMemberNameList);
    };
    f();
  }, [guildData.memberIds, userData.joinedGuildId]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12}>
        <PageTitle>{contentText.guildConfig[userData.language]}</PageTitle>
      </Grid>
      <Grid item xs={12}>
        <ShowGuildMemberList
          guildData={guildData}
          userData={userData}
          uid={uid}
          setIsButtonDisabled={setIsButtonDisabled}
          memberNameList={memberNameList}
        />
        {guildData.name === null ? (
          <>
            <SectionTitle>
              {contentText.createNewGuild[userData.language]}
            </SectionTitle>
            <ChangeGuildName
              setIsButtonDisabled={setIsButtonDisabled}
              language={props.userData.language}
              isNewGuild={true}
              uid={uid}
            />
            <SectionTitle>
              {contentText.joinGuild[userData.language]}
            </SectionTitle>
            <ReceiveGuildInviteCode
              setIsButtonDisabled={setIsButtonDisabled}
              guildId={userData.joinedGuildId}
              isSolo={guildData.memberIds.length === 1}
              language={props.userData.language}
              uid={uid}
            />
          </>
        ) : userData.guildId === userData.joinedGuildId ? (
          <>
            <SectionTitle>
              {contentText.transfarGuildMaster[userData.language]}
            </SectionTitle>
            <ChangeGuildOwner
              guildData={guildData}
              userData={userData}
              uid={uid}
              setIsButtonDisabled={setIsButtonDisabled}
              memberNameList={memberNameList}
            />
            <SectionTitle>
              {contentText.changeGuildName[userData.language]}
            </SectionTitle>
            <ChangeGuildName
              setIsButtonDisabled={setIsButtonDisabled}
              language={props.userData.language}
              isNewGuild={false}
              uid={uid}
            />
            <SectionTitle>
              {contentText.makeInviteCode[userData.language]}
            </SectionTitle>
            <MakeGuildInviteCode
              guildId={userData.joinedGuildId}
              setIsButtonDisabled={setIsButtonDisabled}
              guildName={guildData.name}
              language={props.userData.language}
              uid={uid}
            />
            <SectionTitle>
              {contentText.margeGuild[userData.language]}
            </SectionTitle>
            <ReceiveGuildInviteCode
              setIsButtonDisabled={setIsButtonDisabled}
              guildId={userData.joinedGuildId}
              isSolo={guildData.memberIds.length === 1}
              language={props.userData.language}
              uid={uid}
            />
          </>
        ) : (
          <LeaveGuild
            setIsButtonDisabled={setIsButtonDisabled}
            userData={userData}
            uid={uid}
          />
        )}
      </Grid>
    </>
  );
};
