import { Language, Tutorial } from "../interface";
import { useState, useEffect } from "react";
import { loadTutorialData } from "../api/loadAndSaveDataToDb";
import { useHistory, useParams } from "react-router-dom";
import { contentText } from "./translate/text";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Icon,
} from "@mui/material";
import { labelTexts } from "./TutorialFloorSelect";
import { PageTitle } from "./typography/PageTitle";

export const TutorialFloor = ({ language }: { language: Language }) => {
  const temp = useParams<{ floorIndex: string }>();
  const floorIndex = parseInt(temp.floorIndex, 10) - 1; //0-index
  let history = useHistory();
  const initialTutorial: Tutorial = {
    link: "",
    description: "",
  };
  const [tutorialData, setTutorialData] = useState(initialTutorial);
  useEffect(() => {
    loadTutorialData((floorIndex + 1).toString() + language, setTutorialData);
  }, [floorIndex, language]);

  return (
    <>
      <PageTitle>{labelTexts[language][floorIndex]}</PageTitle>
      <iframe
        title={"example"}
        src={tutorialData.link}
        width="100%"
        height="650px"
      />
      <Grid item xs={12} component="div" sx={{ my: 2 }}>
        <Card>
          <CardContent>
            <Typography variant="body2" component="p">
              {tutorialData.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} component="div" sx={{ my: 2 }}>
        <Button
          variant="contained"
          startIcon={<Icon>menu_book</Icon>}
          onClick={() => history.push("/tutorial")}
        >
          {contentText.backToTutorial[language]}
        </Button>
      </Grid>
    </>
  );
};
