import {
  Grid,
  Typography,
  TextField,
  Button,
  Icon,
  CardContent,
  Card,
} from "@mui/material";
import "firebase/functions";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { saveUserName } from "../../api/loadAndSaveDataToDb";
import { Language } from "../../interface";
import { contentText } from "../translate/text";

export const ChangeUserName = ({
  newUser,
  uid,
  setIsButtonDisabled,
  language,
}: {
  newUser: boolean;
  uid: string;
  setIsButtonDisabled: (arg: boolean) => void;
  language: Language;
}) => {
  // ToDo:setup guildId and joinedGuildId

  const history = useHistory();

  const [userNameInputValue, setUserNameInputValue] = useState("");
  const [message, setMessage] = useState("");
  const [showBackButton, setShowBackButton] = useState(false);

  const clearMessage = () => {
    setMessage("");
  };
  const handleUserNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const newName = e.target.value;
    setUserNameInputValue(newName);
  };
  const handleButton = async () => {
    setIsButtonDisabled(true);
    await saveUserName(
      uid,
      userNameInputValue,
      newUser,
      setMessage,
      setShowBackButton
    );
    setIsButtonDisabled(false);
  };

  return (
    <Card sx={{ my: 2 }}>
      <CardContent>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            {contentText.changeUserName[language]}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            label={contentText.userName[language]}
            value={userNameInputValue}
            onChange={handleUserNameInputValue}
          />
        </Grid>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Button variant="contained" onClick={handleButton}>
            {contentText.saveSetting[language]}
          </Button>
          <div>{message}</div>
        </Grid>
      </CardContent>
    </Card>
  );
};
