import { Language } from "../../interface";
export const contentText = {
  mainGrid: { Japanese: "メイングリッド", English: "Main Grid" },
  instruction: { Japanese: "インストラクション", English: "Instruction" },
  finalProblem: { Japanese: "最終問題", English: "Final Problem" },
  submit: { Japanese: "送信", English: "Submit" },
  correct: { Japanese: "正解", English: "Correct Answer" },
  wrong: { Japanese: "不正解", English: "Wrong Answer" },
  config: { Japanese: "設定", English: "Settings" },
  ranking: { Japanese: "ランキング", English: "Ranking" },
  latestUpdate: { Japanese: "最終更新", English: "Latest Update At" },
  userConfig: { Japanese: "アカウント", English: "Account" },
  guildConfig: { Japanese: "ギルド", English: "Guild" },
  solvedCountRanking: {
    Japanese: "解答数ランキング",
    English: "Solved Count Ranking",
  },
  goToConfig: { Japanese: "設定画面を開く", English: "Open Settings" },
  backToConfig: { Japanese: "設定画面へもどる", English: "Back to Settings" },
  goToUserConfig: { Japanese: "個人設定画面を開く", English: "User settings" },
  tutorialInfo: {
    Japanese: (
      <>
        「Instructionless Grid」を解く上で必要になる(かもしれない)
        penpa-editの機能の使い方を学べます。
        <br />
        左に表示される盤面の状態を右の盤面で再現してみましょう。
        ※正誤判定はありません。
      </>
    ),
    English: (
      <>
        You can learn how to use the penpa - edit function, which you may(or may
        not) need to solve "Instructionless Grid".
        <br />
        Try to reproduce the state of the grid shown on the left with the grid
        on the right.
        <br />
        *There are no answer check.
      </>
    ),
  },
  goToGuildConfig: {
    Japanese: "ギルド設定画面を開く",
    English: "Guild settings",
  },
  backToMainPage: {
    Japanese: "メイングリッドへもどる",
    English: "Back to Main Grid",
  },
  goToTutorial: {
    Japanese: "Penpa-edit チュートリアル",
    English: "Penpa-edit Tutorial",
  },
  backToTutorial: {
    Japanese: "チュートリアル選択画面に戻る",
    English: "Back to Tutorial",
  },
  goToLastProblem: {
    Japanese: "最終問題を見る",
    English: "Show Final Problem",
  },
  changeGuildName: { Japanese: "ギルド名を変更", English: "Change guild name" },
  changeUserName: { Japanese: "ユーザー名の変更", English: "Change user name" },
  createNewGuild: { Japanese: "ギルドを新規作成", English: "Create New Guild" },
  saveSetting: { Japanese: "変更を保存する", English: "Save changes" },
  leaveGuild: { Japanese: "ギルドを退会する", English: "Leave guild" },
  userId: { Japanese: "ユーザーID", English: "User ID" },
  inviteSetting: {
    Japanese: "ギルド招待の送受信",
    English: "Guild invitation settings",
  },
  makeInviteCode: {
    Japanese: "招待コードを発行",
    English: "Generate invitation code",
  },
  loadInviteCode: {
    Japanese: "以前発行したギルド招待コードを確認する",
    English: "Show previous invitation code",
  },
  copyToClipBoard: { Japanese: "コピーする", English: "Copy to clipboard" },
  guildName: { Japanese: "ギルド名", English: "Guild Name" },
  noGuildJoined: { Japanese: "ギルド未所属", English: "No guild joined" },
  userName: { Japanese: "ユーザー名", English: "User Name" },
  noGuildNameError: {
    Japanese: "他のメンバーをギルドに招待する前にギルド名を設定してください",
    English: "Set a name for your guild before inviting other players.",
  },
  saving: { Japanese: "設定中", English: "Saving..." },
  inviteCodeInput: {
    Japanese: "招待コード",
    English: "Invitation code",
  },
  joinGuild: {
    Japanese: "ギルドに参加",
    English: "Join guild",
  },
  joinGuildwithCode: {
    Japanese: "招待コードを利用して既存のギルドに参加できます。",
    English: "Join a guild by using an invitation code.",
  },
  margeGuild: {
    Japanese: "ギルドを併合",
    English: "Merge guild",
  },
  guildInviteError: {
    Japanese:
      "ギルドに参加できませんでした。招待コードが正しいか、また、招待コードが有効かお確かめください。このメッセージが複数回出る場合、時間をおいてお試しください。",
    English:
      "An error occurred and could not join guild. Check your invitation code again, and make sure it is valid. Retry after a few minutes if this message appears several times.",
  },
  guildInviteSuccess: {
    Japanese: "ギルドに参加しました",
    English: "Successfully joined guild.",
  },
  memberList: { Japanese: "メンバーリスト", English: "Member list" },
  kickMember: {
    Japanese: "このメンバーをギルドから退会させますか？",
    English: "Ban this player from your guild?",
  },
  setGuildOwner: {
    Japanese: "このメンバーにギルドマスターを譲渡しますか？",
    English: "Transfer guild master to this member?",
  },
  changeGuildOwner: {
    Japanese: "ギルドマスターを変更",
    English: "Change guild master",
  },
  newAccount: {
    Japanese:
      "アカウントを新規作成します。注意:既にアカウントをお持ちの方がこの操作をするとデータが消える恐れがあります。トップページにお戻りください。",
    English:
      "Create new account. If you alread have an account, go back to the Main Page as this may erase all your data. ",
  },
  example: { Japanese: "例題", English: "Example" },
  openAsNewTab: { Japanese: "別タブで開く", English: "Open in new tab" },
  openAsPenpaEdit: {
    Japanese: "penpa-editで開く",
    English: "Open penpa-edit in new tab",
  },
  exampleAnswerKey: { Japanese: "例題アンサーキー", English: "Example Answer" },
  problem: { Japanese: "問題", English: "Problem" },
  invalidProblemAccess: {
    Japanese:
      "まだこの問題は開放されていません。別の問題を解くことで開放されます。",
    English:
      "This problem has not been unlocked. Solve other unlocked problems to unlock this problem.",
  },
  changeLanguage: { Japanese: "言語の変更", English: "Change Language" },
  welcome: {
    Japanese: "Instructionless Gridへようこそ",
    English: "Welcome To InstructionlessGrid!",
  },
  newUserName1: {
    Japanese: "このサイトで使用する名前を入力してください。",
    English: "Please enter a name to use for this site.",
  },
  newUserName2: {
    Japanese: "後からでも変更できます。",
    English: "You can change this at any time.",
  },
  register: {
    Japanese: "登録",
    English: "Register",
  },
  startGame: {
    Japanese: "グリッドを開始",
    English: "Start The Grid",
  },
  rule: {
    Japanese: "インストラクション",
    English: "Instruction",
  },
  san: {
    Japanese: "さん",
    English: "-san",
  },
  hello: {
    Japanese: "こんにちは ",
    English: "Hi, ",
  },
  whatIsPenpaEdit: {
    Japanese: "こんにちは ",
    English: "Start the penpa-edit tutorial",
  },
  startTutorial: {
    Japanese: "penpa-editのチュートリアルを開始",
    English: "Start the penpa-edit tutorial",
  },
  openProblemInImage: {
    Japanese: "問題を画像で開く",
    English: "Open the problem as an image.",
  },
  close: {
    Japanese: "閉じる",
    English: "Close",
  },
  cancel: {
    Japanese: "キャンセル",
    English: "cancel",
  },
  transfarGuildMaster: {
    Japanese: "ギルドマスターの譲渡",
    English: "Transfer guild master",
  },
  transfarGuildMasterSuccess: {
    Japanese: "にギルドマスターを譲渡しました。",
    English: "Successfully transferred guild master to this player.",
  },
  transfarGuildMasterFailed: {
    Japanese:
      "ギルドマスターの譲渡に失敗しました。時間をおいてもう一度お試しください。",
    English: "Failed to transfer guild master. Please try again later.",
  },
  noGuildMasterSelectError: {
    Japanese: "ギルドマスター譲渡先のユーザーが選択されていません。",
    English: "Choose a player to transfer guild master.",
  },
  banPlayer: {
    Japanese: "メンバーの強制ギルド退会",
    English: "Ban player from your guld",
  },
  banPlayerSuccess: {
    Japanese: "のギルド退会処理に成功しました。",
    English: "Successfully banned this player from your guild.",
  },
  banPlayerFailed: {
    Japanese:
      "ギルド退会処理に失敗しました。時間をおいてもう一度お試しください。",
    English: "Failed to ban from your guld. Please try again later.",
  },
  useInviteCode: {
    Japanese: "招待コードを利用して既存のギルドに参加できます。",
    English: "Merge guilds by using an invitation code.",
  },
  notInAGuild: {
    Japanese: "ギルドに所属していません",
    English: "Not in a guild.",
  },
  youCanCreateNewGuild: {
    Japanese: "ギルド名を入力することで新規ギルドを作成できます。",
    English: "Enter a name to make a new guild.",
  },
  tweet: {
    Japanese: "ツイート",
    English: "Tweet",
  },
  tweetProgress: {
    Japanese: "進捗をツイート",
    English: "Tweet Progress",
  },
  nextPuzzle: {
    Japanese: "次のパズルへ",
    English: "Go to next puzzle",
  },
  correctAnswer: {
    Japanese: "正解です！",
    English: "Correct Answer!",
  },
  shareInTwitter: {
    Japanese: "正解したことをシェアできます。",
    English: "You can tweet that you solved this problem.",
  },
  incorrectAnswer: {
    Japanese: "不正解です！",
    English: "Incorrect answer!",
  },
  pleaseCheckAnswerKey: {
    Japanese: "アンサーキーをお確かめください",
    English: "Check your answer key and try again.",
  },
  leaveGuildDialog: {
    Japanese: "ギルドからの退会",
    English: "Leave guild",
  },
  areYouSureToLeaveGuild: {
    Japanese: "ギルドから退会しますか？",
    English: "Are you sure you want to leave?",
  },
  leaveGuildSuccess: {
    Japanese: "ギルドを退会しました。",
    English: "Successfully left the guild.",
  },
  leaveGuildFailed: {
    Japanese: "ギルドからの退会に失敗しました。時間をおいてお試しください。",
    English: "Failed to leave. Please try again later.",
  },
  endrollTitle: {
    Japanese: "エンドロール",
    English: "endroll",
  },
  endroll: {
    Japanese: "エンドロールを見る",
    English: "Go to endroll",
  },
  caution: {
    Japanese: "注意",
    English: "Caution",
  },
  lastProblemCaution: {
    Japanese: "この画面のギルドメンバー以外への共有を禁止します。",
    English: "Do not share this screen with non-guild members.",
  },
  penpaEditTutorial: {
    Japanese: "penpa-edit操作チュートリアル",
    English: "penpa-edit tutorial",
  },
  userInfo: {
    Japanese: "ユーザー情報",
    English: "User infomation",
  },
  solvedCountByPerson: {
    Japanese: "解答数",
    English: "Solved problem count(Individual)",
  },
  solvedCountByGuild: {
    Japanese: "ギルド解答数",
    English: "Solved problem count(Guild)",
  },
  member: {
    Japanese: "メンバー",
    English: "member",
  },
  solvedCount: {
    Japanese: "解答数",
    English: "Solved Count",
  },
  latestAnswerAt: {
    Japanese: "最終解答時刻",
    English: "Latest Answer At",
  },
  lastProblemAnswerAt: {
    Japanese: "最終問題解答時刻",
    English: "Last Problem Answer At",
  },
  rank: {
    Japanese: "順位",
    English: "Rank",
  },
  additionalExample: {
    Japanese: "追加例題",
    English: "Additional Example",
  },
};
