import {
  ToggleButtonGroup,
  ToggleButton,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Table,
  TableRow,
  Paper,
} from "@mui/material";
import { SetStateAction, useState, useMemo, useEffect } from "react";
import { Guild, User } from "../interface";
import { contentText } from "./translate/text";
import { PageTitle } from "./typography/PageTitle";
import {
  loadAllGuildData,
  loadAllUserData,
  UserWithId,
} from "../api/loadAndSaveDataToDb";
import { SectionTitle } from "./typography/SectionTitle";
import { loadRankingMetaData } from "../api/loadAndSaveDataToDb";

const TestAllRanking = ({
  guildData,
  userData,
}: {
  guildData: Guild;
  userData: User;
}) => {
  const [allGuild, setAllGuild] = useState<Guild[]>();
  const [allUser, setAllUser] = useState<UserWithId[]>();

  useEffect(() => {
    loadAllGuildData(setAllGuild);
    loadAllUserData(setAllUser);
    loadRankingMetaData(setRankingMeta);
  }, []);
  const [rankingMeta, setRankingMeta] = useState<{ ranking: Date }>();

  const userTable: { [key: string]: UserWithId } | undefined = useMemo(() => {
    if (allUser === undefined) {
      return undefined;
    }
    const ret: { [key: string]: UserWithId } = {};
    allUser.forEach((user) => {
      ret[user.uid] = user;
    });
    return ret;
  }, [allUser]);

  const countsAllGuild = useMemo(() => {
    if (!allGuild) {
      return undefined;
    }
    return allGuild
      .filter((e) => e.active)
      .filter((e) => e.solveCountRanking)
      .sort((a, b) => (a?.solveCountRanking ?? 0) - (b?.solveCountRanking ?? 0))
      ;
  }, [allGuild]);

  const handleChange = (event: any, newAlignment: SetStateAction<string>) => {};

  return (
    <>
      <PageTitle>{contentText.ranking[userData.language]}</PageTitle>
      {/*
      <ToggleButtonGroup
        color="primary"
        value={allGuild}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value="counts">解答数</ToggleButton>
        <ToggleButton value="final">最終問題解答タイム</ToggleButton>
        </ToggleButtonGroup>
        */}
      {countsAllGuild && userTable && (
        <>
          <SectionTitle>解答数ランキング</SectionTitle>
          <p>最終更新: {rankingMeta?.ranking.toLocaleString()}</p>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Rank</TableCell>
                  <TableCell>Guild Name</TableCell>
                  <TableCell>Member</TableCell>
                  <TableCell align="right">Counts</TableCell>
                  <TableCell align="right">Latest Answer At</TableCell>
                  <TableCell align="right">Last Problem Answer At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {countsAllGuild.map((guild) => (
                  <TableRow
                    key={guild.solveCountRanking}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {guild.solveCountRanking}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {guild.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {[
                        ...new Set(
                          guild.memberIds.map((uid) => userTable[uid].userName)
                        ),
                      ]
                        .sort()
                        .map((name, i) => (
                          <p key={i}>{name}</p>
                        ))}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {guild.rankingSolvedCount}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {guild.latestAnswerAt?.toDate().toLocaleString()}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {guild.floors?.lastFloor?.clearTime
                        ?.toDate()
                        .toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};
export default TestAllRanking;
