import TwitterIcon from "@mui/icons-material/Twitter";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { correctAnswerSent } from "../api/loadAndSaveDataToDb";
import { User } from "../interface";
import { hashText } from "../util/hashText";
import { contentText } from "./translate/text";

const toHalfWidth = (strVal: string) => {
  // 半角変換
  const halfVal = strVal.replace(/[！-～]/g, (tmpStr: string) => {
    // 文字コードをシフト
    return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0);
  });

  // 文字コードシフトで対応できない文字の変換
  return halfVal
    .replace(/”/g, '"')
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    .replace(/　/g, " ")
    .replace(/〜/g, "~");
};

const AnswerKeyInput = ({
  hashedAnswer,
  floorId,
  userData,
  floorName,
  uid,
}: {
  hashedAnswer: string;
  floorId: string;
  userData: User;
  floorName: string;
  uid: string;
}) => {
  let history = useHistory();
  const [answerKeyInputValue, setAnswerKeyInputValue] = useState("");
  const [alreadyInput, setAlreadyInput] = useState(false);
  const [message, setMessage] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [wrongOpen, setWrongOpen] = useState(false);
  const [isClearDataLoaded, setIsClearDataLoaded] = useState(false);
  const [tweetFloorName, setTweetFloorName] = useState("Puzzle.1");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const savedKey = window.localStorage.getItem(floorId);
    if (savedKey) {
      setAlreadyInput(true);
      setAnswerKeyInputValue(savedKey);
    } else {
      setAlreadyInput(false);
      setAnswerKeyInputValue("");
    }
    if (floorId !== "lastFloor") {
      setTweetFloorName(`Puzzle.${floorId}`);
    } else {
      if (userData.language === "Japanese") {
        setTweetFloorName("最終問題");
      } else {
        setTweetFloorName("Last Problem");
      }
    }
  }, [floorId]);

  const handleTwitterButton = () => {
    if (userData.language === "Japanese") {
      window.open(
        `https://twitter.com/intent/tweet?text=${tweetFloorName}を見事解き明かした！%20%23InstructionlessGrid&url=https://ig.logicpuzzle.app/`
      );
    } else {
      window.open(
        `https://twitter.com/intent/tweet?text=I%20just%20solved%20${tweetFloorName}%20on%20the%20%23InstructionlessGrid&url=https://ig.logicpuzzle.app/`
      );
    }
  };

  const handleAnswerKeyInputValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newAnswerKeyInputValue = e.target.value;
    setAnswerKeyInputValue(newAnswerKeyInputValue);
  };

  useEffect(() => {
    const temp = userData.floors[floorId] ? true : false;
    setIsClearDataLoaded(temp);
  }, [userData, floorId]);

  useEffect(() => {
    if (!isClearDataLoaded && successOpen) {
      //success DialogはuserへのclearDataの反映が終わって初めて開く
      //それまではdisabled
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [isClearDataLoaded, successOpen, floorId]);

  const checkAnswerKey = async () => {
    const convertedValue = toHalfWidth(answerKeyInputValue).toUpperCase();
    setAnswerKeyInputValue(convertedValue);
    const hashedAnswerKeyInputValue: string = await hashText(convertedValue);
    if (hashedAnswer === hashedAnswerKeyInputValue && convertedValue !== "") {
      setIsButtonDisabled(true);
      const result = await correctAnswerSent(
        uid,
        floorId,
        floorName,
        convertedValue
      );
      //successDialogはsuccessOpen && isClearDataLoadedで開くことに注意
      setSuccessOpen(true);

      //userへのclearDataの反映が終わっているならばdisabled解除
      //そうでないならば、反映が終えた段階でuseEffect経由でdisabled解除
      setIsButtonDisabled(!isClearDataLoaded);
      window.localStorage.setItem(floorId, convertedValue);
      setAlreadyInput(true);
    } else {
      setWrongOpen(true);
    }
  };
  const handleClose = () => {
    setSuccessOpen(false);
    setWrongOpen(false);
  };
  const handleNext = () => {
    setSuccessOpen(false);
    setWrongOpen(false);
    window.scroll({ top: 0, behavior: "smooth" });
    if (floorId !== "lastFloor" && floorId !== "100") {
      history.push("/floor/" + (parseInt(floorId) + 1).toString());
    }
  };
  const handleEndroll = () => {
    setSuccessOpen(false);
    setWrongOpen(false);
    history.push("/endroll");
  };

  return (
    <Grid component="div" sx={{ mx: 1, my: 2 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TextField
        type="text"
        value={answerKeyInputValue}
        label="Answer Key"
        disabled={alreadyInput}
        variant="outlined"
        onChange={(e) => handleAnswerKeyInputValue(e)}
        sx={{ mx: 1, my: 1 }}
      ></TextField>
      <Button
        variant="contained"
        onClick={checkAnswerKey}
        endIcon={<Icon>send</Icon>}
        sx={{ my: 2 }}
        size={"large"}
      >
        {contentText.submit[userData.language]}
      </Button>
      <div>{message}</div>
      {
        <Dialog
          open={successOpen && isClearDataLoaded}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          color="success"
        >
          <Alert severity="success">
            <DialogTitle id="alert-dialog-title">
              <AlertTitle
                sx={{
                  fontSize: "34px",
                  fontWeight: "bold",
                }}
              >
                {contentText.correctAnswer[userData.language]}
              </AlertTitle>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {userData.language === "Japanese"
                  ? `${tweetFloorName} を見事解き明かした！`
                  : `You solved ${tweetFloorName}.`}

                <br />
                {contentText.shareInTwitter[userData.language]}
                <br />
                <Button
                  variant="contained"
                  startIcon={<TwitterIcon />}
                  color="info"
                  sx={{ my: 1 }}
                  onClick={handleTwitterButton}
                >
                  {contentText.tweet[userData.language]}
                </Button>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                {contentText.close[userData.language]}
              </Button>
              {floorId !== "lastFloor" && floorId !== "100" ? (
                <Button onClick={handleNext}>
                  {contentText.nextPuzzle[userData.language]}
                </Button>
              ) : floorId === "lastFloor" ? (
                <Button onClick={handleEndroll}>
                  {contentText.endroll[userData.language]}
                </Button>
              ) : (
                <></>
              )}
            </DialogActions>
          </Alert>
        </Dialog>
      }
      {
        <Dialog
          open={wrongOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          color="error"
        >
          <Alert severity="error">
            <DialogTitle id="alert-dialog-title">
              <AlertTitle
                sx={{
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                {contentText.incorrectAnswer[userData.language]}
              </AlertTitle>
            </DialogTitle>
            <DialogContentText id="alert-dialog-description">
              {contentText.pleaseCheckAnswerKey[userData.language]}
            </DialogContentText>
            <DialogActions>
              <Button onClick={handleClose}>
                {contentText.close[userData.language]}
              </Button>
            </DialogActions>
          </Alert>
        </Dialog>
      }
    </Grid>
  );
};
export default AnswerKeyInput;
