import { useEffect, useState, useMemo } from "react";
import { changeGuildOwner } from "../../api/loadAndSaveDataToDb";
import { Guild, User } from "../../interface";

import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { contentText } from "../translate/text";

export const ChangeGuildOwner = ({
  guildData,
  userData,
  uid,
  setIsButtonDisabled,
  memberNameList,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
  setIsButtonDisabled: (arg: boolean) => void;
  memberNameList: { memberName: string; memberId: string }[];
}) => {
  const [newOwnerId, setNewOwnerId] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setNewOwnerId(event.target.value);
  };

  useEffect(() => {
    setNewOwnerId("");
  }, [memberNameList]);

  const newOwnerName = useMemo(() => {
    return memberNameList.find((e) => e.memberId === newOwnerId)?.memberName;
  }, [newOwnerId]);

  const [ownerChangeDialogOpen, setOwnerChangeDialogOpen] = useState(false);
  const handleOwnerChangeDialogOpen = () => {
    setOwnerChangeDialogOpen(true);
  };
  const handleOwnerChangeDialogClose = () => {
    setOwnerChangeDialogOpen(false);
  };

  const [ownerChangeResultDialogOpen, setOwnerChangeResultDialogOpen] =
    useState(false);
  const handleOwnerChangeResultDialogOpen = () => {
    setOwnerChangeResultDialogOpen(true);
  };
  const handleOwnerChangeResultDialogClose = () => {
    setOwnerChangeResultDialogOpen(false);
  };

  const [noSelectErrorDialogOpen, setNoSelectErrorDialogOpen] = useState(false);
  const handleNoSelectErrorDialogOpen = () => {
    setNoSelectErrorDialogOpen(true);
  };
  const handleNoSelectErrorDialogClose = () => {
    setNoSelectErrorDialogOpen(false);
  };

  const [ownerChangeResult, setOwnerChangeResult] = useState(false);

  const handleOwnerChangeButton = async (memberId: string) => {
    setIsButtonDisabled(true);
    handleOwnerChangeDialogClose();
    const result = await changeGuildOwner(
      uid,
      userData.joinedGuildId,
      memberId
    );
    setIsButtonDisabled(false);
    setOwnerChangeResult(result?.data ?? false);
    handleOwnerChangeResultDialogOpen();
  };

  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="simple-select-label">newOwner</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={newOwnerId}
            label="newOwner"
            onChange={handleChange}
          >
            {memberNameList.map((data) => {
              if (data.memberId !== uid) {
                return (
                  <MenuItem value={data.memberId}>
                    {contentText.userName[userData.language]} :{" "}
                    {data.memberName}, ID : {data.memberId}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={() => {
            if (newOwnerId === "") {
              handleNoSelectErrorDialogOpen();
            } else {
              handleOwnerChangeDialogOpen();
            }
          }}
        >
          {contentText.transfarGuildMaster[userData.language]}
        </Button>
      </Box>

      <Dialog
        open={ownerChangeDialogOpen}
        onClose={handleOwnerChangeDialogClose}
        aria-labelledby="alert-Ownerchange-title"
        aria-describedby="alert-Ownerchange-description"
      >
        <DialogTitle id="alert-Ownerchange-title">
          {contentText.transfarGuildMaster[userData.language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-Ownerchange-description">
            {contentText.userName[userData.language]}:{newOwnerName}
            <br />
            ID:{newOwnerId}
            <br />
            {contentText.setGuildOwner[userData.language]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOwnerChangeDialogClose}>
            {contentText.cancel[userData.language]}
          </Button>
          <Button
            onClick={() => {
              handleOwnerChangeButton(newOwnerId);
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ownerChangeResultDialogOpen}
        onClose={handleOwnerChangeResultDialogClose}
        aria-labelledby="alert-OwnerchangeResult-title"
        aria-describedby="alert-OwnerchangeResult-description"
      >
        <DialogTitle id="alert-OwnerchangeResult-title">
          {contentText.transfarGuildMaster[userData.language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-OwnerchangeResult-description">
            {ownerChangeResult ? (
              <>
                {contentText.userName[userData.language]}:{newOwnerName}
                <br />
                ID:{newOwnerId}
                <br />
                {contentText.transfarGuildMasterSuccess[userData.language]}
              </>
            ) : (
              <>{contentText.transfarGuildMasterFailed[userData.language]}</>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOwnerChangeResultDialogClose}>
            {contentText.close[userData.language]}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={noSelectErrorDialogOpen}
        onClose={handleNoSelectErrorDialogClose}
        aria-labelledby="alert-noSelectError-title"
        aria-describedby="alert-noSelectError-description"
      >
        <DialogTitle id="alert-noSelectError-title">
          {contentText.transfarGuildMaster[userData.language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-noSelectError-description">
            {contentText.noGuildMasterSelectError[userData.language]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoSelectErrorDialogClose}>
            {contentText.close[userData.language]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
