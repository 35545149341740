import { Grid, Typography, TextField, Button } from "@mui/material";
import { useState } from "react";
import { saveGuildName } from "../../api/loadAndSaveDataToDb";
import { Language } from "../../interface";
import { contentText } from "../translate/text";

export const ChangeGuildName = ({
  setIsButtonDisabled,
  language,
  isNewGuild,
  uid,
}: {
  setIsButtonDisabled: (arg: boolean) => void;
  language: Language;
  isNewGuild: boolean;
  uid: string;
}) => {
  const [guildNameInputValue, setGuildNameInputValue] = useState("");
  const [message, setMessage] = useState("");

  const clearMessage = () => {
    setMessage("");
  };
  const handleGuildNameInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMessage();
    const newName = e.target.value;
    setGuildNameInputValue(newName);
  };
  const handleButton = async () => {
    setIsButtonDisabled(true);
    setMessage(contentText.saving[language]);
    await saveGuildName(uid, guildNameInputValue, setMessage);
    setIsButtonDisabled(false);
  };

  return (
    <Grid item xs={12} sx={{ my: 2 }}>
      {isNewGuild && <p>{contentText.youCanCreateNewGuild[language]}</p>}
      <TextField
        variant="outlined"
        label={contentText.guildName[language]}
        value={guildNameInputValue}
        onChange={handleGuildNameInputValue}
      ></TextField>

      <Grid item xs={12} component="div" sx={{ my: 2 }}>
        <Button variant="contained" color="primary" onClick={handleButton}>
          {isNewGuild
            ? contentText.createNewGuild[language]
            : contentText.saveSetting[language]}
        </Button>
        <div>{message}</div>
      </Grid>
    </Grid>
  );
};
