import { deepPurple } from "@mui/material/colors";
import firebase from "firebase";
import { initialGuild, initialUser } from "../data/initialData";
import {
  Floor,
  Guild,
  exampleImageSet,
  Language,
  Tutorial,
  User,
} from "../interface";
import { hashText } from "../util/hashText";
import { userConverter } from "./firebaseConverter";

const REGION = "asia-northeast1";

export const correctAnswerSent = async (
  uid: string,
  floorId: string,
  floorName: string,
  rowAnswer: string
) => {
  let returnData: { isCorrect: boolean } = {
    isCorrect: false,
  };
  const hashedFloorName = (await hashText(floorName)).slice(0, 8);
  const onCallFromTokyo = firebase
    .app()
    .functions(REGION)
    .httpsCallable("checkAnswer");
  try {
    await onCallFromTokyo({
      floorId: floorId,
      floorName: hashedFloorName,
      rowAnswer: rowAnswer,
      uid: uid,
    }).then((result) => {
      returnData.isCorrect = result.data?.isCorrect;
    });
  } catch (e) {
    throw e;
  }
  return returnData;
};

export const getUserParentUid = (uid: string): Promise<string> => {
  const db = firebase.firestore();
  const docRef = db.collection("user").doc(uid);
  return docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data()?.parentUid;
      } else {
        return undefined;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const loadUserData = (
  uid: string | undefined,
  setUserData: (userData: User) => void,
  setUserDataLoaded: (arg: boolean) => void
) => {
  if (uid === undefined) {
    return () => {};
  }
  const db = firebase.firestore();
  const docRef = db.collection("user").doc(uid);
  let success = false;
  const stopUserDataSnapshot = docRef.onSnapshot((doc) => {
    if (doc.exists) {
      const newUserData: User = {
        userName: doc.data()?.userName,
        guildId: doc.data()?.guildId,
        joinedGuildId: doc.data()?.joinedGuildId,
        solvedCount: doc.data()?.solvedCount,
        floors: doc.data()?.floors,
        language: doc.data()?.language,
        isAccountLinkedWithMail: doc.data()?.isAccountLinkedWithMail,
        parentUid: doc.data()?.parentUid,
      };
      setUserData(newUserData);
      success = true;
    } else {
      console.log("No such document!");
    }
    setUserDataLoaded(true);
  });

  return stopUserDataSnapshot;
};

export type UserWithId = User & { uid: string };
export const loadAllUserData = (setAllUserData: (g: UserWithId[]) => void) => {
  const db = firebase.firestore();
  const colRef = db.collection("user");
  let success = false;
  const stopUserDataSnapshot = colRef.get().then((col) => {
    const allUserData = col.docs.map((doc) => ({
      userName: doc.data()?.userName,
      guildId: doc.data()?.guildId,
      joinedGuildId: doc.data()?.joinedGuildId,
      solvedCount: doc.data()?.solvedCount,
      floors: doc.data()?.floors,
      language: doc.data()?.language,
      uid: doc.id,
    }));
    setAllUserData(allUserData);
  });
  return stopUserDataSnapshot;
};

export const loadGuildData = (
  guildId: string,
  setGuildData: (guildData: Guild) => void
) => {
  const db = firebase.firestore();
  const docRef = db.collection("guild").doc(guildId);
  let success = false;
  const stopGuildDataSnapshot = docRef.onSnapshot((doc) => {
    if (doc.exists) {
      const newGuildData: Guild = {
        name: doc.data()?.name,
        memberIds: doc.data()?.memberIds,
        solvedCount: doc.data()?.solvedCount,
        floors: doc.data()?.floors,
        active: doc.data()?.active,
        guildOwner: doc.data()?.guildOwner,
        solveCountRanking: doc.data()?.solveCountRanking,
        lastProblemTimeRanking: doc.data()?.lastProblemTimeRanking,
      };
      setGuildData(newGuildData);
      success = true;
    } else {
      setGuildData(initialGuild);
      console.log("No such document!");
    }
  });
  return stopGuildDataSnapshot;
};

export const loadAllGuildData = (setAllGuildData: (g: Guild[]) => void) => {
  const db = firebase.firestore();
  const colRef = db.collection("guild");
  let success = false;
  const stopGuildDataSnapshot = colRef.get().then((col) => {
    const allGuildData = col.docs.map((doc) => ({
      name: doc.data()?.name,
      memberIds: doc.data()?.memberIds,
      solvedCount: doc.data()?.solvedCount,
      floors: doc.data()?.floors,
      guildOwner: doc.data()?.guildOwner,
      active: doc.data()?.active,
      solveCountRanking: doc.data()?.solveCountRanking,
      latestAnswerAt: doc.data()?.latestAnswerAt,
      rankingSolvedCount: doc.data()?.rankingSolvedCount,
    }));
    setAllGuildData(allGuildData);
  });
  return stopGuildDataSnapshot;
};

export const loadRankingMetaData = async (
  setRankingMeta: (rankingMetaData: { ranking: Date }) => void
) => {
  const db = firebase.firestore();
  db.collection("doc")
    .doc("ranking")
    .get()
    .then((doc) => {
      const data = {
        ranking: doc.data()?.ranking.toDate(),
      };
      setRankingMeta(data);
    });
};

export const loadFloorData = async (
  floorName: string,
  setFloorData: (arg: Floor) => void
) => {
  const db = firebase.firestore();

  const floorId = (await hashText(floorName)).slice(0, 8);
  const docRef = db.collection("floor").doc(floorId);
  docRef
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const storageRef = firebase.storage().ref();
        const promiseArray = [
          storageRef.child(floorName + "_example.png").getDownloadURL(),
          storageRef.child(floorName + "_example_answer.png").getDownloadURL(),
          storageRef.child(floorName + "_problem.png").getDownloadURL(),
        ];
        const isAdditionalExampleExist =
          doc.data()?.additionalExampleCount > 0 &&
          typeof doc.data()?.additionalExampleCount === "number";
        if (isAdditionalExampleExist) {
          for (let i = 1; i <= doc.data()?.additionalExampleCount; i++) {
            promiseArray.push(
              storageRef
                .child(`${floorName}_additional_example${i}.png`)
                .getDownloadURL()
            );
            promiseArray.push(
              storageRef
                .child(`${floorName}_additional_example${i}_answer.png`)
                .getDownloadURL()
            );
          }
        }
        const URLs = await Promise.allSettled(promiseArray);
        //PromiseSettledResult<any>の取り扱いがうまくいかないのでanyで一回受ける
        const URLs_: any[] = URLs;
        const exampleImageUrl = URLs_[0]?.value;
        const exampleAnswerImageUrl = URLs_[1]?.value;
        const problemImageUrl = URLs_[2]?.value;
        const additionalExampleImage: exampleImageSet[] = [];
        if (isAdditionalExampleExist) {
          for (let i = 0; i < doc.data()?.additionalExampleCount; i++) {
            additionalExampleImage.push({
              problemUrl: URLs_[3 + 2 * i]?.value,
              answerUrl: URLs_[3 + 2 * i + 1]?.value,
            });
          }
        }

        const newFloorData: Floor = {
          exampleImage: exampleImageUrl,
          exampleAnswerImage: exampleAnswerImageUrl,
          exampleLink: doc.data()?.exampleLink,
          exampleAnswerKey: doc.data()?.exampleAnswerKey,
          problemImage: problemImageUrl,
          problemLink: doc.data()?.problemLink,
          hashedAnswer: doc.data()?.hashedAnswer,
          additionalExampleCount: isAdditionalExampleExist
            ? doc.data()?.additionalExampleCount
            : 0,
          additionalExampleImage: additionalExampleImage,
          additionalExampleUrl: doc.data()?.additionalExampleUrl,
        };
        setFloorData(newFloorData);
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const loadTutorialData = (
  floorName: string,
  setFloorData: (arg: Tutorial) => void
) => {
  const db = firebase.firestore();
  const docRef = db.collection("tutorial").doc(floorName);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const newFloorData: Tutorial = {
          link: doc.data()?.link,
          description: doc.data()?.description,
        };
        setFloorData(newFloorData);
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const saveGuildName = async (
  uid: string,
  guildNameInputValue: string,
  setMessage: (message: string) => void
) => {
  if (guildNameInputValue !== "") {
    const setNewGuild = firebase
      .app()
      .functions(REGION) // Functionsのデプロイされているregion指定
      .httpsCallable("updateGuildName"); // Functionsの関数名指定
    try {
      if (await setNewGuild({ newGuildName: guildNameInputValue, uid: uid })) {
        //多分違う関数呼ぶべき
        setMessage("saved");
      } else {
        setMessage("failed saving");
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    setMessage("Enter guild Name!");
  }
};

export const saveUserName = async (
  uid: string,
  userNameInputValue: string,
  newUser: boolean,
  setMessage: (message: string) => void,
  setShowBackButton: (arg: boolean) => void
) => {
  if (userNameInputValue !== "") {
    if (newUser) {
      const setNewUser = firebase
        .app()
        .functions(REGION) // Functionsのデプロイされているregion指定
        .httpsCallable("createUser"); // Functionsの関数名指定
      try {
        await setNewUser({ newUserName: userNameInputValue, uid: uid });
        setMessage("saved");
        setShowBackButton(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      const setNewName = firebase
        .app()
        .functions(REGION) // Functionsのデプロイされているregion指定
        .httpsCallable("updateName"); // Functionsの関数名指定
      try {
        await setNewName({ newUserName: userNameInputValue, uid: uid });
        setMessage("saved");
      } catch (e) {
        console.log(e);
      }
    }
  } else {
    setMessage("Enter user Name!");
  }
};

export const makeGuildInvite = async (uid: string) => {
  const makeInviteCode = firebase
    .app()
    .functions(REGION) // Functionsのデプロイされているregion指定
    .httpsCallable("makeInviteCode"); // Functionsの関数名指定
  try {
    const result = await makeInviteCode({ uid: uid });
    if (result.data?.success) {
    } else {
      console.log(result.data?.reason);
    }
  } catch (e) {
    console.log(e);
  }
};

export const loadGuildAdminData = async (
  guildId: string,
  setGuildInviteCode: (inviteCode: string) => void
) => {
  const db = firebase.firestore();
  const docRef = db.collection("guildAdmin").doc(guildId);
  return await docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const newInviteCode: string = doc.data()?.inviteCode;
        if (newInviteCode) {
          setGuildInviteCode(newInviteCode);
          return true;
        } else {
          console.log("no data :doc.data().?inviteCode");
          return false;
        }
      } else {
        console.log("No such document!");
        return false;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return false;
    });
};

export const receiveGuildInviteCode = async (
  inviteCode: string,
  oldGuildId: string,
  uid: string
) => {
  const receiveInviteCode = firebase
    .app()
    .functions(REGION) // Functionsのデプロイされているregion指定
    .httpsCallable("receiveInviteCode"); // Functionsの関数名指定
  try {
    const result = await receiveInviteCode({
      inviteCode,
      oldGuildId,
      uid,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const kickMember = async (uid: string, kickUid: string) => {
  const kickGuildMember = firebase
    .app()
    .functions(REGION) // Functionsのデプロイされているregion指定
    .httpsCallable("kickGuildMember"); // Functionsの関数名指定
  try {
    const result = await kickGuildMember({ kickUid, uid });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const leaveGuild = async (uid: string) => {
  const leaveFromGuild = firebase
    .app()
    .functions(REGION) // Functionsのデプロイされているregion指定
    .httpsCallable("leaveGuild"); // Functionsの関数名指定
  try {
    const result = await leaveFromGuild({ uid });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const changeGuildOwner = async (
  uid: string,
  guildId: string,
  newOwnerId: string
) => {
  const changeOwner = firebase
    .app()
    .functions(REGION) // Functionsのデプロイされているregion指定
    .httpsCallable("changeGuildOwner"); // Functionsの関数名指定
  try {
    const result = await changeOwner({
      uid: uid,
      guildId: guildId,
      newOwnerId: newOwnerId,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getLastFloorData = async () => {
  const db = firebase.firestore();
  const docRef = db.collection("floor").doc("7b740409");
  const data = (await docRef.get()).data();
  return data;
};

export const saveUserLanguage = async (
  uid: string,
  userData: User,
  language: Language
) => {
  const saveUserLanguage = firebase
    .app()
    .functions(REGION) // Functionsのデプロイされているregion指定
    .httpsCallable("saveUserLanguage"); // Functionsの関数名指定
  try {
    const result = await saveUserLanguage({
      uid,
      userData,
      language,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getRevealedFloorNumber = async () => {
  const db = firebase.firestore();
  const docRef = db
    .collection("revealedFloorNumber")
    .doc("revealedFloorNumber");
  const doc = await docRef.get().catch((error) => {
    console.log("Error getting document:", error);
  });
  if (doc) {
    if (typeof doc.data()?.revealedFloorNumber === "number") {
      return doc.data()?.revealedFloorNumber;
    } else {
      console.log("No revealedFloorNumber or type error");
    }
  }
};

export const getUserData = async (userId: string) => {
  const db = firebase.firestore();
  const docRef = db.collection("user").withConverter(userConverter).doc(userId);
  const userData = await docRef.get().then((doc) => {
    return doc.data();
  });
  return userData;
};

/*
export const getUserName = async (memberId: string) => {
  const memberData = await getUserData(memberId);
  return { memberName: memberData?.userName, memberId };
};
*/

export const getUserName = async (memberId: string) => {
  const db = firebase.firestore();
  const docRef = db.collection("user").doc(memberId);
  const memberName = await docRef.get().then((doc) => {
    let newMemberName = "";
    if (doc.exists) {
      newMemberName = doc.data()?.userName;
    }
    return newMemberName;
  });
  return { memberName, memberId };
};

export const getParentUser = async (lpAppUid: string) => {
  const db = firebase.firestore();
  const linkedParentUidDocRef = db.collection("linkedParentUid").doc(lpAppUid);
  const parent = await linkedParentUidDocRef.get().then(async (doc) => {
    if (doc.exists) {
      const parentUid: string = doc.data()?.parentUid;
      const temp = await getUserData(parentUid);
      let guildName = "";
      if (temp?.joinedGuildId) {
        guildName = (
          await db.collection("guild").doc(temp.joinedGuildId).get()
        ).data()?.name;
      }
      return {
        userName: temp?.userName ?? "",
        userId: parentUid,
        joinedGuildId: temp?.joinedGuildId ?? "",
        guildName,
      };
    } else {
      return { userName: "", userId: "", joinedGuildId: "", guildName: "" };
    }
  });
  return parent;
};
