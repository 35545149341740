import {
  Typography,
  Button,
  Divider,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Paper,
  CardContent,
  Icon,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { loadFloorData } from "../api/loadAndSaveDataToDb";
import { initialFloor } from "../data/initialData";
import { User } from "../interface";
import AnswerKeyInput from "./AnswerKeyInput";
import { contentText } from "./translate/text";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PageTitle } from "./typography/PageTitle";

//日英の出しわけ
const languageDependFloorList = [97, 98, 99, 100];

const Floor = ({
  isFloorsRevealed,
  userData,
  uid,
}: {
  isFloorsRevealed: boolean[];
  userData: User;
  uid: string;
}) => {
  const temp = useParams<{ floorIndex: string }>();
  const [floorName, setFloorName] = useState("");
  const [floorIndex, setFloorIndex] = useState(-1); //0-index
  let history = useHistory();

  //floorDataをサーバーから持ってくる
  const [floorData, setFloorData] = useState(initialFloor);
  useEffect(() => {
    const tempFloorIndexNum = parseInt(temp.floorIndex, 10);
    setFloorIndex(tempFloorIndexNum - 1);
    setFloorName(
      languageDependFloorList.includes(tempFloorIndexNum)
        ? temp.floorIndex + userData.language
        : temp.floorIndex
    );
  }, [temp.floorIndex, isFloorsRevealed, userData.language]);

  useEffect(() => {
    if (floorName) {
      loadFloorData(floorName, setFloorData);
    }
  }, [floorName]);

  const [size, setSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const onResize = React.useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, [setSize]);

  window.addEventListener("resize", onResize, true);
  return (
    <div>
      {isFloorsRevealed[floorIndex] ? (
        <div>
          <PageTitle>Puzzle.{floorIndex + 1}</PageTitle>
          <Card sx={{ my: 2 }}>
            <CardContent>
              <Typography variant="h4" component="p">
                {contentText.example[userData.language]}
              </Typography>
              <img src={floorData.exampleImage} width="50%" height="auto"></img>
              <img
                src={floorData.exampleAnswerImage}
                width="50%"
                height="auto"
              ></img>
              <Typography
                variant="h5"
                component="p"
                sx={{ width: "100%", overflow: "scroll" }}
              >
                {contentText.exampleAnswerKey[userData.language]}：
                {floorData.exampleAnswerKey}
              </Typography>
              <Grid component="p">
                <Button
                  variant="contained"
                  onClick={() => window.open(floorData.exampleLink)}
                >
                  {contentText.openAsPenpaEdit[userData.language]}
                </Button>
              </Grid>
              {floorData.additionalExampleCount > 0 ? (
                <>
                  <Typography variant="h4" component="p">
                    {contentText.additionalExample[userData.language]}
                  </Typography>
                  {floorData.additionalExampleImage.map((v, i) => {
                    const url = floorData?.additionalExampleUrl?.[i];
                    return (
                      <>
                        <img src={v.problemUrl} width="50%" height="auto"></img>
                        <img src={v.answerUrl} width="50%" height="auto"></img>
                        {url && (
                          <Grid component="p">
                            <Button
                              variant="contained"
                              onClick={() => window.open(url)}
                            >
                              {contentText.openAsPenpaEdit[userData.language]}
                            </Button>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </CardContent>
          </Card>

          <Card sx={{ my: 2 }}>
            <CardContent>
              <Typography variant="h4" component="p">
                {contentText.problem[userData.language]}
              </Typography>
              {size.width > 500 ? (
                <div
                  style={{
                    width: "100%",
                    height: "960px",
                  }}
                >
                  <iframe
                    title={"problem"}
                    src={floorData.problemLink}
                    width="100%"
                    height="960px"
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: "576px",
                    height: "580px",
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    title={"problem"}
                    src={floorData.problemLink}
                    width="90%"
                    height="960px"
                    style={{
                      transformOrigin: "0 0",
                      transform: "scale(0.59)",
                      overflow: "hidden",
                    }}
                  />
                </div>
              )}

              <Button
                variant="contained"
                onClick={() => window.open(floorData.problemLink)}
                sx={{ my: 2 }}
              >
                {contentText.openAsPenpaEdit[userData.language]}
              </Button>
              <Grid component="div" sx={{ my: 1 }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      {contentText.openProblemInImage[userData.language]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <img
                      src={floorData.problemImage}
                      width="80%"
                      height="auto"
                    ></img>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <AnswerKeyInput
                uid={uid}
                hashedAnswer={floorData.hashedAnswer}
                floorId={(floorIndex + 1).toString()}
                userData={userData}
                floorName={floorName}
              />
            </CardContent>
          </Card>
        </div>
      ) : (
        <div>{contentText.invalidProblemAccess[userData.language]} </div>
      )}

      <Grid component="div" sx={{ my: 1 }}>
        <Button
          variant="contained"
          startIcon={<Icon>apps</Icon>}
          onClick={() => {
            history.push("/");
          }}
        >
          {contentText.backToMainPage[userData.language]}
        </Button>
      </Grid>
    </div>
  );
};
export default Floor;
