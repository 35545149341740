import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { receiveGuildInviteCode } from "../../api/loadAndSaveDataToDb";
import { Language } from "../../interface";
import { contentText } from "../translate/text";

export const ReceiveGuildInviteCode = ({
  setIsButtonDisabled,
  guildId,
  isSolo,
  language,
  uid,
}: {
  setIsButtonDisabled: (arg: boolean) => void;
  guildId: string;
  isSolo: boolean;
  language: Language;
  uid: string;
}) => {
  const [inviteCode, setInviteCode] = useState("");
  const handleInviteCode = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInviteCode(e.target.value);
    setMessage("");
  };

  const [message, setMessage] = useState("");

  const handleButton = async () => {
    setIsButtonDisabled(true);
    const result = await receiveGuildInviteCode(inviteCode, guildId, uid);
    if (!result?.data?.success) {
      setMessage(contentText.guildInviteError[language]);
    } else {
      setMessage(contentText.guildInviteSuccess[language]);
    }
    setIsButtonDisabled(false);
  };
  return (
    <Grid item xs={12}>
      <p>{contentText.useInviteCode[language]}</p>
      <Grid item>
        <TextField
          value={inviteCode}
          label={contentText.inviteCodeInput[language]}
          variant="outlined"
          onChange={(e) => {
            handleInviteCode(e);
          }}
        ></TextField>
      </Grid>
      {isSolo ? (
        <Button variant="contained" onClick={() => handleButton()}>
          {contentText.joinGuild[language]}
        </Button>
      ) : (
        <>
          <Button variant="contained" onClick={() => handleButton()}>
            {contentText.margeGuild[language]}
          </Button>
        </>
      )}
      <div>{message}</div>
    </Grid>
  );
};
