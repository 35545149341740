import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { Guild, User } from "../interface";
import { ChangeLanguage } from "./ConfigContents/ChangeLanguage";
import { ChangeUserName } from "./ConfigContents/ChangeUserName";
import { GuildConfig } from "./ConfigContents/GuildConfig";
import { NewUser } from "./NewUser";
import { contentText } from "./translate/text";
import { PageTitle } from "./typography/PageTitle";

export type ConfigProps = {
  guildData: Guild;
  userData: User;
  uid: string;
};

export const Config = ({
  guildData,
  userData,
  uid,
  setUid,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
  setUid: (prop: string) => void;
}) => {
  const props = { guildData, userData, uid };
  const history = useHistory();

  //各種設定中は他のボタンを押させない
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  return (
    <>
      <Grid container spacing={2}>
        <Switch>
          <Route exact path="/config">
            <Grid item xs={12}>
              <Typography variant="h4" component="h1">
                {contentText.config[userData.language]}
              </Typography>
              <Button
                variant="contained"
                startIcon={<Icon>person</Icon>}
                onClick={() => history.push("/config/user")}
              >
                {contentText.goToUserConfig[userData.language]}
              </Button>
              <Button
                variant="contained"
                startIcon={<Icon>group</Icon>}
                onClick={() => history.push("/config/guild")}
              >
                {contentText.goToGuildConfig[userData.language]}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                disabled={isButtonDisabled}
                startIcon={<Icon>apps</Icon>}
                onClick={() => history.push("/")}
              >
                {contentText.backToMainPage[userData.language]}
              </Button>
            </Grid>
          </Route>
          <Route exact path="/config/new-user">
            <NewUser
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
              {...props}
            ></NewUser>
          </Route>
          <Route exact path="/config/user">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isButtonDisabled}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item xs={12}>
              <PageTitle>
                {contentText.userConfig[props.userData.language]}
              </PageTitle>
            </Grid>
            <ChangeLanguage
              props={props}
              setIsButtonDisabled={setIsButtonDisabled}
            ></ChangeLanguage>
            <ChangeUserName
              newUser={false}
              uid={uid}
              setIsButtonDisabled={setIsButtonDisabled}
              language={props.userData.language}
            />
            <Card>
              <CardContent>
                <Typography variant="h4" component="h1">
                  {contentText.userInfo[userData.language]}
                </Typography>
                <p>
                  {contentText.userName[userData.language]}: {userData.userName}
                </p>
                <p>
                  {contentText.userId[userData.language]}: {uid}
                </p>
                <p>
                  {contentText.guildName[userData.language]}: {guildData.name}
                </p>
                <p>
                  {contentText.solvedCountByPerson[userData.language]}:{" "}
                  {userData.solvedCount}
                </p>
                <p>
                  {contentText.solvedCountByGuild[userData.language]}:{" "}
                  {guildData.solvedCount}
                </p>
              </CardContent>
            </Card>
          </Route>
          <Route exact path="/config/guild">
            <GuildConfig
              props={props}
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
            />
          </Route>
        </Switch>
        <Divider />
      </Grid>
    </>
  );
};
